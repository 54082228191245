<template>
  <v-dialog v-model="show" persistent v-bind="$attrs" max-width="35%">
    <v-card min-width="35%" min-height="130px">
      <v-app-bar dense outlined color="#006EC5">
        <div class="white--text text-body-1">
          Tải lên tờ khai hải quan
        </div>
        <v-spacer></v-spacer>
        <v-btn icon @click="close" small class="mr-1">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <div class="pa-2 overflow-auto">
        <UploadFile
          v-model="form.file"
          :multiple="false"
          :typeAllow="['pdf']"
          accept="application/pdf"
        ></UploadFile>
      </div>
      <div class="pb-3 mr-3 text-end">
        <v-btn color="#006EC5" hide-details @click="handleUpload">
          <v-icon left color="white" small
            >mdi-arrow-up-bold-box-outline</v-icon
          >
          <span class="white--text">
            Tải lên
          </span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    UploadFile: () => import("@/components/upload/UploadFile"),
  },
  props: {
    value: null,
  },
  data: () => ({
    show: false,
    form: {
      file: null,
    },
  }),
  computed: {},
  methods: {
    close() {
      this.form = this.$options.data.call(this).form;
      this.show = false;
    },
    open() {
      this.show = true;
    },
    handleUpload() {
      let form = new FormData();
      form.append("file", this.form);
      //

      // api request
      // this.$confirmBox.show({
      //     title: "Import chứng từ",
      //     width: 500,
      //     body:
      //       "Bạn có chắc chắn muốn import dữ liệu từ file chứng từ này vào tờ khai không?",
      //     action: async () => await this.importItem(item),
      //     onDone: () => ({}),
      //   });
    },
  },
};
</script>

<style scoped></style>
